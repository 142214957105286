import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import NetworkService from './networkService';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { QRCode } from 'react-qrcode-logo';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { LinearProgress } from '@mui/material';

const networkService = new NetworkService();

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Network() {
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [currConfig, setCurrConfig] = useState({});

    const [status, setStatus] = useState(
        {
            "wifi": {
                "enabled": "true",
                "ssid": "FastRTK",
                "passphrase": "fastrtk!",
                "country": "us"
            },
            "lan": {
                "ip": "0.0.0.0",
                "gateway": "0.0.0.0",
                "subnet": "255.255.255.0",
                "dns": "0.0.0.0",
                "externalIP": "0.0.0.0"
            }
        }
    );

    const [wifiEnabled, setWifiEnabled] = useState(true);
    const [wifiSSID, setWifiSSID] = useState("");
    const [wifiPassphrase, setWifiPassphrase] = useState("");
    const [wifiCountry, setWifiCountry] = useState("");

    const [networktype, setNetworktype] = useState("dhcp");

    const [staticIP, setStaticIP] = useState("");
    const [staticIPvalid, setStaticIPvalid] = useState(true);

    const [staticRouter, setStaticRouter] = useState("");
    const [staticRoutervalid, setStaticRoutervalid] = useState(true);

    const [staticNetMask, setStaticNetMask] = React.useState("");

    const [staticDNS1, setStaticDNS1] = useState("");
    const [staticDNS1valid, setStaticDNS1valid] = useState(true);

    const [staticDNS2, setStaticDNS2] = useState("");
    const [staticDNS2valid, setStaticDNS2valid] = useState(true);



    useEffect(() => {
        setLoading(true);
        networkService.getNetwork().then(response => {
            setCurrConfig({
                "type": response.type,
                "ip": response.ip,
                "mask": response.mask,
                "gateway": response.gateway,
                "dns": response.dns,
                "port": response.gateway,
                "wifi": response.wifi,
                "pswd": response.pswd,
                "internalIP": response.internalIP,
                "externalIP": response.externalIP,
                "macAddress": response.mac_address
            })
            setWifiSSID(generateSSID(response.mac_address));
            setLoading(false);
        });
    }, []);

    const handleChangeNetmask = (event) => {
        setStaticNetMask(event.target.value);
    };

    const onIPChange = (event) => {
        let ip = cleanIP(event.target.value);
        setStaticIP(ip);
        setStaticIPvalid(validateIP(ip));
    };

    const onRouterChange = (event) => {
        let ip = cleanIP(event.target.value);
        setStaticRouter(ip);
        setStaticRoutervalid(validateIP(ip));
    };

    const onDNS1Change = (event) => {
        let ip = cleanIP(event.target.value);
        setStaticDNS1(ip);
        setStaticDNS1valid(validateIP(ip));
    };

    const onDNS2Change = (event) => {
        let ip = cleanIP(event.target.value);
        setStaticDNS2(ip);
        setStaticDNS2valid(validateIP(ip));
    };

    const setDefaultRouter = () => {
        if (staticRouter === "") {
            let router = staticIP.substr(0, staticIP.lastIndexOf(".") + 1);
            router = router + "1";
            setStaticRouter(router);
        }
    }

    const setDefaultDNS1 = () => {
        if (staticDNS1 === "") {
            let ip = staticIP.substr(0, staticIP.lastIndexOf(".") + 1);
            ip = ip + "1";
            setStaticDNS1(ip);
        }
    }

    const setDefaultDNS2 = () => {
        if (staticDNS1 === "") {
            setStaticDNS2("8.8.8.8");
        }
    }

    const validateIP = (ip) => {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip)) {
            return true;
        }
        else {
            return false;
        }
    }

    const cleanIP = (ip) => {
        return ip.replace(/[^0-9.]/g, '');
    }

    function setDHCP() {
        var params = {
            type: "dhcp"
        };

        networkService.setNetwork(params).then((response) => {
            alert("Determine DHCP assigned IP address then navigate your browser to it.")
        })
            .catch(function (error) {
                alert("Failed to set network to DHCP.")
            })
    }

    function setStatic() {
        var params = {
            type: "static",
            ip: staticIP,
            netmask: staticNetMask,
            router: staticRouter,
            dns1: staticDNS1,
            dns2: staticDNS2
        };

        networkService.setNetwork(params).then(async (response) => {
            alert("Static IP configuration set. Reconnecting...");
            await new Promise(r => setTimeout(r, 2000));
            window.location.href = 'http://' + staticIP;
        })
            .catch(function (error) {
                alert("Failed to set static IP configuration.")
            })
    }

    const handleNetworkType = (event, newNetworkType) => {
        setNetworktype(newNetworkType);
    };

    function generateSSID(value) {
        let mac = value;
        console.log("cleaned up: ", mac);
        let cleanedMac = mac.replace(/:/g, "");
        console.log("cleaned up: ", cleanedMac);
        let shortMac = cleanedMac.slice(-7);
        return "FastRTK-" + shortMac;
    }

    return (
        <React.Fragment>
            <h1 className="page-title"><span className="fw-semi-bold">Network Settings</span></h1>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={<h5>LAN</h5>} {...a11yProps(0)} />
                        <Tab label={<h5>WiFI</h5>} {...a11yProps(1)} />
                        <Tab label={<h5>VPN</h5>} {...a11yProps(2)} />
                    </Tabs>
                </Box>
                {loading ? <LinearProgress /> : (
                    <React.Fragment>
                <TabPanel value={value} index={0}>
                    <h3>Status</h3>
                    Internal IP: {currConfig.internalIP}
                    <br />
                    Public IP: {currConfig.externalIP}
                    <br /><br />
                    MAC Address: {currConfig.macAddress}
                    <br /><br />
                    <h3>Configure</h3>
                    <Stack spacing={2} direction="column" alignItems="center" style={{ maxWidth: "20em" }}>
                        <ToggleButtonGroup
                            value={networktype}
                            exclusive
                            onChange={handleNetworkType}
                            aria-label="Network type"
                        >
                            <ToggleButton value="dhcp">DHCP</ToggleButton>
                            <ToggleButton value="static">Static</ToggleButton>
                        </ToggleButtonGroup>

                        {networktype === "static" ? (
                            <React.Fragment>
                                <TextField
                                    fullWidth
                                    id="static-ip"
                                    label="Static IP"
                                    onChange={onIPChange}
                                    value={staticIP}
                                    error={!staticIPvalid}
                                    helperText={!staticIPvalid ? "Invalid IP address" : "Enter a valid IP address."}
                                />
                                <FormControl fullWidth>
                                    <InputLabel id="static-netmask">Netmask</InputLabel>
                                    <Select
                                        labelId="static-netmask"
                                        id="static-netmask-select"
                                        value={staticNetMask}
                                        label="Netmask"
                                        onChange={handleChangeNetmask}
                                    >
                                        <MenuItem value={30}>255.255.255.252</MenuItem>
                                        <MenuItem value={29}>255.255.255.248</MenuItem>
                                        <MenuItem value={28}>255.255.255.240</MenuItem>
                                        <MenuItem value={27}>255.255.255.224</MenuItem>
                                        <MenuItem value={26}>255.255.255.192</MenuItem>
                                        <MenuItem value={25}>255.255.255.128</MenuItem>
                                        <MenuItem value={24}>255.255.255.0</MenuItem>
                                        <MenuItem value={23}>255.255.254.0</MenuItem>
                                        <MenuItem value={22}>255.255.252.0</MenuItem>
                                        <MenuItem value={21}>255.255.248.0</MenuItem>
                                        <MenuItem value={20}>255.255.240.0</MenuItem>
                                        <MenuItem value={19}>255.255.224.0</MenuItem>
                                        <MenuItem value={18}>255.255.192.0</MenuItem>
                                        <MenuItem value={17}>255.255.128.0</MenuItem>
                                        <MenuItem value={16}>255.255.0.0</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    id="static-router"
                                    label="Router"
                                    onChange={onRouterChange}
                                    onFocus={setDefaultRouter}
                                    value={staticRouter}
                                    error={!staticRoutervalid}
                                    helperText={!staticRoutervalid ? "Invalid IP address" : "Enter a valid IP address."}
                                />
                                <TextField
                                    fullWidth
                                    id="static-dns1"
                                    label="DNS 1"
                                    onChange={onDNS1Change}
                                    onFocus={setDefaultDNS1}
                                    value={staticDNS1}
                                    error={!staticDNS1valid}
                                    helperText={!staticDNS1valid ? "Invalid IP address" : "Enter a valid IP address."}
                                />
                                <TextField
                                    fullWidth
                                    id="static-dns2"
                                    label="DNS 2"
                                    onChange={onDNS2Change}
                                    onFocus={setDefaultDNS2}
                                    value={staticDNS2}
                                    error={!staticDNS2valid}
                                    helperText={!staticDNS2valid ? "Invalid IP address" : "Enter a valid IP address."}
                                />
                                <Button
                                    variant="text"
                                    onClick={setStatic}
                                >Set Static IP Configuration</Button>
                            </React.Fragment>
                        ) : (
                            <Button
                                variant="text"
                                onClick={setDHCP}
                            >Set DHCP IP Configuration</Button>
                        )}
                    </Stack>

                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Stack direction="row" spacing={2}>
                        <h2>Status</h2>
                        <FormControlLabel disabled control={<Switch defaultChecked />} label="Enabled" />
                    </Stack>
                    SSID: {wifiSSID}
                    <br />
                    Passphrase: fastrtk!
                    <br /><br />
                    <QRCode
                        fgColor="#1a4b87"
                        bgColor="#ffffff"
                        // value={"WIFI:S:" + "FastRTK" + ";T:WPA2;P:" + "fastrtk!" + ";H:false;"}
                        value={"WIFI:S:{wifiSSID};T:WPA2;P:fastrtk!;H:false;"}
                        logoOpacity={0.5}
                        removeQrCodeBehindLogo={false}
                        qrStyle="dots"
                        logoWidth="120"
                        quietZone={15}
                        eyeRadius={0.1}
                        eyeColor="#1a4b87"
                    />
                    <br />
                    Scan QR code to connect.
                    <br />
                    {/* <br />
                    <h2>Configure</h2>
                    <Stack spacing={2} direction="column" style={{ maxWidth: "20em" }}>
                        <TextField
                            helperText="Enter an SSID."
                            id="wifi-ssid"
                            label="SSID"
                        />
                        <TextField
                            helperText="Enter a password."
                            id="wifi-password"
                            label="Password"
                        />
                        <Button variant="text">Save</Button>
                    </Stack> */}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <VPN />
                </TabPanel>
                    </React.Fragment>
                )}
            </Box>
        </React.Fragment >
    );
}


function VPN() {
    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const [isFilePicked, setIsFilePicked] = useState(false);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const handleSubmission = () => {
        const formData = new FormData();

        formData.append('file', selectedFile);

        networkService.uploadVpnConfig(formData).then(() => {
            alert("VPN File uploaded!")
        });
    };

    return (
        <React.Fragment>

            <Stack direction="row" spacing={2}>
                <h2>Status</h2>
                <FormControlLabel control={<Switch defaultChecked />} label="Enabled" />
            </Stack>
            <h2>Upload VPN configuration file</h2>
            <Stack spacing={2} direction="column" style={{ maxWidth: "20em" }}>
                {/* <input type="file" name="file" onChange={changeHandler} /> */}
                {isSelected ? (
                    <React.Fragment>
                        <span>Filename: {selectedFile.name}</span>
                        <span>Size: {selectedFile.size}b</span>
                    </React.Fragment>
                ) : (<span>No file selected</span>)}
                {!isSelected ? (<Button
                    variant="contained"
                    component="label"
                >
                    Select File
                    <input
                        type="file"
                        hidden
                        onChange={changeHandler}
                    />
                </Button>
                ) : (
                    <Button variant="contained" onClick={handleSubmission}>Upload</Button>
                )}
            </Stack>
        </React.Fragment>
    )
}