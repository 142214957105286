import React, { useEffect, useState } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  NavLink
} from "react-router-dom";
import './App.css';
import Dashboard from "./pages/dashboard/Dashboard";
import Network from './pages/network/Network';
import Data from "./pages/gnss_receiver/data/data";
import NtripUsersList from './pages/users/ntrip/list';
import NtripUserEdit from './pages/users/ntrip/edit';
import AdminUsersList from './pages/users/admin/list';
import About from "./pages/about/about";
import RelayList from './pages/relays/relayList';
import RelayCreate from './pages/relays/relayCreate';
import Activity from './pages/activity/activity';
import SourceList from './pages/sources/sourceList';
import GNSSTools from './pages/gnss_receiver/tools/tools';
import GnssCoordinate from './pages/gnss_receiver/coordinate/coordinate';

/* RTK Components */
import RTKServiceList from './pages/gnss_receiver/rtk/list';
import RTKServiceStatus from './pages/gnss_receiver/rtk/status';
import RTKServiceCreate from './pages/gnss_receiver/rtk/create';
import RTKServiceEdit from './pages/gnss_receiver/rtk/edit';

/* RINEX Components */
import RINEXServiceList from './pages/gnss_receiver/rinex/list';
import RINEXServiceStatus from './pages/gnss_receiver/rinex/status';
import RINEXServiceCreate from './pages/gnss_receiver/rinex/create';
import RINEXServiceEdit from './pages/gnss_receiver/rinex/edit';

import Login from './pages/login/Login';
import { history } from './util/history';
import { API_URL } from './config';
import axiosInstance from './util/axiosAPI';
import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from './redux/store/auth.slice';

import './styles/theme.scss';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import GroupSharpIcon from '@mui/icons-material/GroupSharp';
import LanSharpIcon from '@mui/icons-material/LanSharp';
import GpsFixedSharpIcon from '@mui/icons-material/GpsFixedSharp';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import BuildSharpIcon from '@mui/icons-material/BuildSharp';
import LogoutIcon from '@mui/icons-material/Logout';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import PublicIcon from '@mui/icons-material/Public';
import HubIcon from '@mui/icons-material/Hub';
import CableIcon from '@mui/icons-material/Cable';
import SaveIcon from '@mui/icons-material/Save';
import CasterTools from './pages/caster/tools';
import SystemTools from './pages/system/tools';
import MountpointEdit from './pages/mountpoints/edit';
import SettingsInputHdmiIcon from '@mui/icons-material/SettingsInputHdmi';
import GnssControllerSettings from './pages/gnss_receiver/rtk/form';
import { LinearProgress } from '@mui/material';
import RelayUpdate from './pages/relays/relayUpdate';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function App() {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("FastRTK Admin");
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  history.navigate = useNavigate();
  history.location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const authenticated = useSelector(x => x.auth.authenticated);
  const dispatch = useDispatch();

  // Check authentication
  useEffect(() => {
    let access_token = localStorage.getItem('access');
    if (access_token) {

      axiosInstance.post(`${API_URL}/api/token/verify/`, { token: access_token })
        .then((response) => {
          dispatch(login());
          setLoading(false);
        })
        .catch(function (error) {
          console.log("Token invalid or expired!");
          dispatch(logout());
          setLoading(false);
        });
    }
    setLoading(false);
  }, []);

  if (loading) return <LinearProgress />;
  else if (!authenticated) {
    return (<Login />);
  }
  else {
      return (
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="fixed" open={open} style={{ backgroundColor: "#2d5986" }}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <SatelliteAltIcon style={{ marginRight: 10 }} />
              <Typography variant="h6" noWrap component="div">
                {title}
              </Typography>
              <div style={{ position: "absolute", right: "0px", marginRight: "10px" }}>
                {localStorage.getItem('username')}
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => dispatch(logout())}
                  edge="start"
                  sx={{
                    marginLeft: 1,
                    marginRight: 1,
                  }}
                >
                  <LogoutIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader>
              <Typography variant="h6" noWrap component="div">
                Menu
              </Typography>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem key='dashboard' disablePadding sx={{ display: 'block' }} >
                <NavLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK Live Dashboard")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <PublicIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Live Dashboard'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>

              <ListItem key='activity' disablePadding sx={{ display: 'block' }} >
                <NavLink to="/activity" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK Login Activity")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <TroubleshootIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Activity'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem key='caster' sx={{ display: 'block' }}>
                <ListItemText primary='NTRIP Caster' sx={{ opacity: open ? 1 : 0 }} />
              </ListItem>
              <ListItem key='users-ntrip' disablePadding sx={{ display: 'block' }} >
                <NavLink to="/users/ntrip" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK NTRIP Users")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <GroupSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary='NTRIP Users' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
              <ListItem key='sources' disablePadding sx={{ display: 'block' }}>
                <NavLink to="/caster/sources" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK Push-In Sources")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <CableIcon />
                    </ListItemIcon>
                    <ListItemText primary='Push-In Sources' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
              <ListItem key='caster-relays' disablePadding sx={{ display: 'block' }}>
                <NavLink to="/caster/relays" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK NTRIP Relays")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <HubIcon />
                    </ListItemIcon>
                    <ListItemText primary='NTRIP Relays' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
              <ListItem key='caster-tools' disablePadding sx={{ display: 'block' }}>
                <NavLink to="/caster/tools" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK Caster Tools")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <BuildSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary='Tools' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem key='gnss' sx={{ display: 'block' }}>
                <ListItemText primary='GNSS' sx={{ opacity: open ? 1 : 0 }} />
              </ListItem>
              {/* <ListItem key='gnss-status' disablePadding sx={{ display: 'block' }}>
                <NavLink to="/gnss/status" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK GNSS Status")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <SatelliteAltIcon />
                    </ListItemIcon>
                    <ListItemText primary='GNSS Status' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem> */}
              <ListItem key='gnss-rtk' disablePadding sx={{ display: 'block' }}>
                <NavLink to="/gnss/rtk" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK RTK Streaming")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <SettingsInputAntennaIcon />
                    </ListItemIcon>
                    <ListItemText primary='RTK Streaming' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
              <ListItem key='gnss-logging' disablePadding sx={{ display: 'block' }}>
                <NavLink to="/gnss/rinex" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK GNSS Logging")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <SaveAltIcon />
                    </ListItemIcon>
                    <ListItemText primary='RINEX Logging' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
              <ListItem key='gnss-coordinate' disablePadding sx={{ display: 'block' }}>
                <NavLink to="/gnss/coordinate" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK Reference Coordinate")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <GpsFixedSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary='Coordinate' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
              <ListItem key='gnss-data' disablePadding sx={{ display: 'block' }}>
                <NavLink to="/gnss/data" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK GNSS Data")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <SaveIcon />
                    </ListItemIcon>
                    <ListItemText primary='Data' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
              {/* <ListItem key='gnss-settings' disablePadding sx={{ display: 'block' }}>
                <NavLink to="/gnss/settings" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK GNSS Settings")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <SatelliteAltIcon />
                    </ListItemIcon>
                    <ListItemText primary='Settings' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem> */}
              <ListItem key='gnss-tools' disablePadding sx={{ display: 'block' }}>
                <NavLink to="/gnss/tools" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK GNSS Tools")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <BuildSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary='Tools' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem key='system' sx={{ display: 'block' }}>
                <ListItemText primary='System' sx={{ opacity: open ? 1 : 0 }} />
              </ListItem>
              <ListItem key='users-admin' disablePadding sx={{ display: 'block' }} >
                <NavLink to="/users/admin" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK Admin Users")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <AdminPanelSettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary='Admin Users' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
              <ListItem key='system-network' disablePadding sx={{ display: 'block' }}>
                <NavLink to="/system/network" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK Network Settings")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <LanSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary='Network' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
              <ListItem key='system-tools' disablePadding sx={{ display: 'block' }}>
                <NavLink to="/system/tools" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK System Tools")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <BuildSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary='Tools' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
              <ListItem key='about' disablePadding sx={{ display: 'block' }}>
                <NavLink to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItemButton
                    onClick={() => setTitle("FastRTK About")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <InfoSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary='About' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
            </List>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />

            <Routes>
              <Route path="" element={<Dashboard />} />
              <Route path="activity">
                <Route path="" element={<Activity />} />
              </Route>


              <Route path="users">
                <Route path="admin" element={<AdminUsersList />} />
                <Route path="ntrip">
                  <Route path="" element={<NtripUsersList />} />
                  <Route path=":id" element={<NtripUserEdit />} />
                </Route>
              </Route>
              <Route path="caster">
                <Route path="relays">
                  <Route path="" element={<RelayList />} />
                  <Route path="new" element={<RelayCreate />} />
                  <Route path=":id" element={<RelayUpdate />} />
                </Route>
                <Route path="mountpoint">
                  <Route path=":id" element={<MountpointEdit />} />
                </Route>
                <Route path="sources" element={<SourceList />} />
                <Route path="tools" element={<CasterTools />} />
              </Route>
              <Route path="gnss">

                {/* <Route path="" element={<GNSSServices />} /> */}

                <Route path="rtk" element={<RTKServiceList />} />
                <Route path="rtk/:id" element={<RTKServiceStatus />} />
                <Route path="rtk/:id/edit" element={<RTKServiceEdit />} />
                <Route path="rtk/new" element={<RTKServiceCreate />} />

                <Route path="rinex" element={<RINEXServiceList />} />
                <Route path="rinex/:id" element={<RINEXServiceStatus />} />
                <Route path="rinex/:id/edit" element={<RINEXServiceEdit />} />
                <Route path="rinex/new" element={<RINEXServiceCreate />} />

                <Route path="coordinate" element={<GnssCoordinate />} />
                <Route path="connect" element={<GnssControllerSettings />} />
                {/* <Route path="settings" element={<RTKBase />} /> */}
                <Route path="data" element={<Data />} />
                <Route path="tools" element={<GNSSTools />} />
              </Route>
              <Route path="system">
                <Route path="network" element={<Network />} />
                <Route path="tools" element={<SystemTools />} />
              </Route>
              <Route path="about" element={<About />} />
            </Routes>
          </Box>
        </Box >
      );
  }
}

export default App;